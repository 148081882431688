import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
@Injectable({
	providedIn: 'root',
})
export class VehicleApi {
	constructor(private http: HttpClient) {}
	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public isLoading$ = this.isLoadingSubject.asObservable()

	private isDownloadSubject = new BehaviorSubject<boolean>(false)
	public isDownloadLoading$ = this.isDownloadSubject.asObservable()

	private isUploadSubject = new BehaviorSubject<boolean>(false)
	public isUploadLoading$ = this.isUploadSubject.asObservable()

	private isDeleteMteSubject = new BehaviorSubject<boolean>(false)
	public isDeleteMteLoading$ = this.isDeleteMteSubject.asObservable()

	index = (params: any = {}): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `vehicles`
		return this.http.get(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	store = (params: any = {}) => {
		this.isLoadingSubject.next(true)
		const url = `vehicles`
		return this.http.post(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	show = (uuid: string) => {
		this.isLoadingSubject.next(true)
		const url = `vehicles/${uuid}`
		return this.http.get(url).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	uploadMte = (uuid: string, binaryString: any, headers: any) => {
		this.isUploadSubject.next(true)
		const url = `vehicles/${uuid}/mte`
		return this.http.post(url, binaryString, { headers }).pipe(finalize(() => this.isUploadSubject.next(false)))
	}

	downloadMte = (uuid: string) => {
		this.isDownloadSubject.next(true)
		const url = `vehicles/${uuid}/mte`
		return this.http.get(url, { responseType: 'blob' }).pipe(finalize(() => this.isDownloadSubject.next(false)))
	}

	deleteMte = (uuid: string) => {
		this.isDeleteMteSubject.next(true)
		const url = `vehicles/${uuid}/mte`
		return this.http.delete(url).pipe(finalize(() => this.isDeleteMteSubject.next(false)))
	}

	uploadAttachment = (id: string, binaryString: any, headers: any) => {
		this.isUploadSubject.next(true)
		const url = `vehicles/${id}/attachments`
		return this.http.post(url, binaryString, { headers }).pipe(finalize(() => this.isUploadSubject.next(false)))
	}

	attachments = (id: string, params: any = {}): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `vehicles/${id}/attachments`
		return this.http.get(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}
}
