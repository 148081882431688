<ng-template #content>
	<div class="modal-header">
		<h2>Task List</h2>
		<div
			class="btn btn-sm btn-icon btn-active-color-primary"
			(click)="close()">
			<span class="bi bi-x-lg"></span>
		</div>
	</div>

	<div class="modal-body">
		<div class="flex-lg-row-fluid print-hide">
			<ul
				class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
				role="tablist">
				<li
					class="nav-item"
					role="presentation"
					(click)="selectTab('new')">
					<div
						class="nav-link text-active-primary cursor-pointer"
						[class.active]="checkIsSelected('new')"
						data-bs-toggle="tab"
						href="/"
						aria-selected="true"
						role="tab">
						New / Processing
					</div>
				</li>

				<li
					class="nav-item"
					(click)="selectTab('completed')">
					<div
						class="nav-link text-active-primary me-6 cursor-pointer"
						[class.active]="checkIsSelected('completed')">
						Completed
					</div>
				</li>
			</ul>
			<div class="mb-5 mb-lg-10">
				<div
					[ngClass]="{
						'overlay overlay-block min-h-200px': isLoading$ | async
					}">
					<div
						class="overlay-layer card-rounded d-flex flex-column"
						*ngIf="isLoading$ | async">
						<div
							class="spinner-border text-black"
							role="status"></div>
					</div>
					<div *ngIf="!(isLoading$ | async)">
						<div *ngIf="tasks.length">
							<div
								*ngFor="let task of tasks; let i = index"
								class="d-flex task mb-6"
								(click)="openTask(task)">
								<div class="row w-100">
									<div class="col-2 col-md-1">
										<div class="symbol-group symbol-hover pt-2">
											<div class="symbol symbol-50px symbol-circle">
												<ng-template #tipContent>
													<div class="min-w-125px">
														<b>{{ task.admin.name }}</b>
													</div>
												</ng-template>

												<span
													class="symbol-label fs-3"
													[ngClass]="avatarClass(i)"
													[ngbTooltip]="tipContent">
													{{ task.admin.name.charAt(0) }}
												</span>
											</div>
										</div>
									</div>
									<div class="col-10 col-md-11">
										<div class="d-flex flex-column">
											<div class="row w-100 align-items-center mb-2 pb-2 title-border">
												<div class="col-md-3 col-12">
													<div class="d-flex w-100 align-items-center">
														<span class="me-4 fs-3 text-gray-700">Title:</span>
														<span class="fs-2 fw-bold text-trancate-1">{{ task.title }}</span>
													</div>
												</div>
												<div class="col-md-9 col-12">
													<div class="d-flex w-100 align-items-center">
														<span class="me-4 fs-3 text-gray-700">Invovle Person:</span>
														<div>
															<div class="symbol-group symbol-hover">
																<div
																	class="symbol symbol-35px symbol-circle"
																	*ngFor="let member of task.assignments; let y = index">
																	<ng-template #tipContent2>
																		<div class="min-w-125px">
																			<b>{{ member.assign_to.name }}</b>
																		</div>
																	</ng-template>

																	<span
																		class="symbol-label 40px"
																		[ngClass]="avatarClass(y)"
																		[ngbTooltip]="tipContent2">
																		{{ member.assign_to.name.charAt(0) }}
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="mb-6 d-flex align-items-center gap-4">
												<span class="text-1 min-w-100px">Assigner:</span>
												<span class="text-trancate-2 flex-6">
													{{ task.admin.name }}
												</span>
											</div>
											<div class="mb-6 d-flex align-items-center gap-4">
												<span class="text-1 min-w-100px">Time:</span>
												<span class="text-trancate-2 flex-6">
													{{ task.created_at | customDate }}
												</span>
											</div>
											<div class="mb-6 d-flex align-items-center gap-4">
												<span class="text-1 min-w-100px">Status:</span>
												<span
													class="text-trancate-2 flex-6"
													[innerHTML]="getStatusBadge(task.status, false, 'tasks')"></span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							*ngIf="!tasks.length"
							class="d-flex justify-content-center min-h-100px align-items-center">
							<i class="fs-2 text-gray-800 fw-semibold">-- Data not found --</i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="d-flex gap-4">
			<button
				type="button"
				class="btn btn-secondary btn-sm"
				(click)="close()">
				<span>Close</span>
			</button>
		</div>
	</div>
</ng-template>
