import { ChangeDetectorRef, Directive, HostListener } from '@angular/core'
import { TaskService } from '@services/task.service'

@Directive({
	selector: '[openTaskModal]',
})
export class OpenTaskModalDirective {
	constructor(
		private cdr: ChangeDetectorRef,
		private taskService: TaskService
	) {}

	@HostListener('click') onClick() {
		this.taskService.open()
	}
}
