import { HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpInterceptor as originHttpInterceptor } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslationService } from '@assets/i18n'
import { environment } from '@env/environment'
import { formatPayload, isEmpty } from '@helper/helper'
import { CacheService } from '@services/cache.service'
import { FormService } from '@services/form.service'
import { ToastService } from '@shared/toast/toast.service'
import { Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { AuthService } from './auth.service'
import { BridgeService } from './bridge.service'

@Injectable({
	providedIn: 'root',
})
export class HttpInterceptor implements originHttpInterceptor {
	constructor(
		private authService: AuthService,
		private router: Router,
		private toastService: ToastService,
		private bridgeService: BridgeService,
		private translationService: TranslationService,
		private formService: FormService,
		private cacheService: CacheService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let bearerToken: string | undefined = request.headers.get('Authorization') || ''

		let country: string = request.headers.get('Country') || ''
		let requestHasCountryHeader: boolean = false

		if (!isEmpty(country)) {
			requestHasCountryHeader = true
		}

		if (!bearerToken) {
			let user = this.authService.getAuthFromLocalStorage()
			bearerToken = `Bearer ${user?.bearerToken}`

			if (!requestHasCountryHeader) {
				country = user?.country || 'sg'
			}
		}

		let domain: any = environment.apiUrl

		let url = request.url.replace(/^\//, '')

		if (url.includes('.svg')) domain = ''

		let formatedParams = request.params
		let formatedBody = request.body

		let ip = localStorage.getItem('ipAddress')

		request = request.clone({
			url: `${domain}${url}`,
			params: formatedParams,
			body: formatedBody,
			reportProgress: true,
			setHeaders: {
				Authorization: bearerToken,
				Accept: `application/json`,
				Country: country,
				IP: ip || '',
				'Content-Type': `application/json`,
				'Accept-Language': this.translationService?.getSelectedLanguage() || 'en',
			},
		})

		if (request.method === 'GET') {
			const cachedResponse = this.cacheService.get(request.urlWithParams)
			if (cachedResponse) {
				return of(cachedResponse)
			}
		}

		if (['POST', 'PUT', 'DELETE'].includes(request.method)) {
			this.cacheService.clear()
		}

		return next.handle(request).pipe(
			tap({
				next: (event: any) => {
					if (event instanceof HttpResponse) {
						if (request.method === 'GET') {
							this.cacheService.set(request.urlWithParams, event)
						}

						if (event.body?.data) {
							formatPayload(event.body.data, 'add')
						}
					}
				},
				error: (error: any) => {
					if (error?.status) {
						switch (error.status) {
							case 400:
								Swal.fire({
									title: 'System Reminder',
									text: error.error.message || error.error?.data?.message || 'Unknown Error',
									icon: 'error',
								})
								break
							case 401:
								if (error?.error?.mfa == 'disabled') {
									if (error?.error?.user) {
										this.authService.setAuthFromLocalStorage(error.error.user)
										return this.router.navigate(['/auth/google2fa'])
									}
								}
								this.cacheService.clear()
								this.authService.logout({ silent: true })
								break
							case 403:
								this.bridgeService.update({
									pageLoading: false,
									pageError: 403,
								})
								break
							case 404:
								this.router.navigate(['**'])
								break
							case 422:
								if (this.formService.forms.length) {
									this.formService.setErrors(error)
								}

								break
							case 500:
								this.toastService.show('Please try again later', {
									delay: 3000,
									icon: `bi bi-exclamation-triangle`,
									autohide: true,
									classname: 'bg-danger text-white',
									headertext: 'Oops...Something Wrong',
								})
								break
						}
					}
				},
			})
		)
	}

	clearCache(): void {
		this.cacheService.clear()
	}
}
