<div class="mb-6">
	<label class="text-gray-800 fs-4 fw-semibold mb-3">Histories</label>
	<div class="max-h-450px overflow-scroll pe-4">
		<div
			*ngFor="let history of histories; let i = index"
			class="d-flex align-items-center mb-4 gap-4">
			<div class="symbol-group symbol-hover">
				<div class="symbol symbol-50px symbol-circle">
					<ng-template #tipContent>
						<div class="min-w-125px">
							<b>{{ history?.remark?.data?.from?.name ? history?.remark?.data?.from?.name : history?.guardable.name }}</b>
						</div>
					</ng-template>

					<span
						class="symbol-label 40px"
						[ngbTooltip]="tipContent">
						{{ history?.remark?.data?.from?.name ? history?.remark?.data?.from?.name.charAt(0) : history?.guardable.name.charAt(0) }}
					</span>
				</div>
			</div>
			<div class="d-flex flex-column w-100">
				<div *ngIf="history?.remark?.data?.action == 'processingTask'">
					<div class="d-flex align-items-center">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.remark?.data?.from?.uuid; else otherPeople">You</span>
							<ng-template #otherPeople>{{ history?.remark?.data?.from?.name }}</ng-template>
						</div>
						<div class="fs-4">mark this task as processing</div>
					</div>
				</div>
				<div *ngIf="history?.remark?.data?.action == 'completeTask'">
					<div class="d-flex align-items-center">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.remark?.data?.from?.uuid; else otherPeople">You</span>
							<ng-template #otherPeople>{{ history?.remark?.data?.from?.name }}</ng-template>
						</div>
						<div class="fs-4">mark this task as completed</div>
					</div>
				</div>
				<div
					class="d-flex align-items-center"
					*ngIf="history?.remark?.data?.action == 'markAsComplete' || history?.remark?.data?.action == 'markAsIncomplete'">
					<div class="d-flex align-items-center">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.remark?.data?.from?.uuid; else otherPeople">You</span>
							<ng-template #otherPeople>{{ history?.remark?.data?.from?.name }}</ng-template>
						</div>
						<div class="fs-4">
							mark checklist
							<b>{{ history?.remark.data?.itemName }}</b>
							as
							{{ history.remark.data.action == 'markAsIncomplete' ? 'incompleted' : 'completed' }}
						</div>
					</div>
				</div>
				<div
					class="d-flex align-items-center"
					*ngIf="history?.remark?.data?.action == 'assign'">
					<div
						class="d-flex align-items-center"
						*ngIf="history?.remark?.data.from.uuid == history?.remark?.data.to.uuid">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.remark?.data?.from?.uuid; else otherFrom">You</span>
							<ng-template #otherFrom>{{ history?.remark?.data?.from?.name }}</ng-template>
						</div>
						<div class="fs-4">join this task</div>
					</div>
					<div
						class="d-flex align-items-center"
						*ngIf="history?.remark?.data.from.uuid != history?.remark?.data.to.uuid">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.remark?.data?.from?.uuid; else otherFrom">You</span>
							<ng-template #otherFrom>{{ history?.remark?.data?.from?.name }}</ng-template>
						</div>
						<div class="me-2 fs-4">
							<span>assign</span>
						</div>
						<div
							class="fw-bold fs-3"
							*ngIf="history?.remark?.data?.to?.name">
							<span *ngIf="me.uuid == history?.remark?.data?.to?.uuid; else otherTo">You</span>
							<ng-template #otherTo>{{ history?.remark?.data?.to?.name }}</ng-template>
						</div>
						<div class="mx-2 fs-4">
							<span>to this task</span>
						</div>
					</div>
				</div>
				<div
					class="d-flex align-items-center"
					*ngIf="history?.remark?.data?.action == 'remove'">
					<div
						class="d-flex align-items-center"
						*ngIf="history?.remark?.data.from.uuid == history?.remark?.data.to.uuid">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.remark?.data?.from?.uuid; else otherFrom">You</span>
							<ng-template #otherFrom>{{ history?.remark?.data?.from?.name }}</ng-template>
						</div>
						<div class="fs-4">leave this task</div>
					</div>
					<div
						class="d-flex align-items-center"
						*ngIf="history?.remark?.data.from.uuid != history?.remark?.data.to.uuid">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.remark?.data?.from?.uuid; else otherFrom">You</span>
							<ng-template #otherFrom>{{ history?.remark?.data?.from?.name }}</ng-template>
						</div>
						<div class="me-2 fs-4">
							<span>remove</span>
						</div>
						<div
							class="fw-bold fs-3"
							*ngIf="history?.remark?.data?.to?.name">
							<span *ngIf="me.uuid == history?.remark?.data?.to?.uuid; else otherTo">You</span>
							<ng-template #otherTo>{{ history?.remark?.data?.to?.name }}</ng-template>
						</div>
						<div class="mx-2 fs-4">
							<span>from this task</span>
						</div>
					</div>
				</div>
				<div *ngIf="!isEmpty(history?.old_values) && !isEmpty(history.new_values)">
					<div class="d-flex align-items-center">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.guardable.uuid; else otherAdmin">You</span>
							<ng-template #otherAdmin>{{ history?.guardable?.name }}</ng-template>
						</div>
						<div class="me-2 fs-4">
							<span>
								update
								<b>{{ getKeys(history?.old_values) }}</b>
								from this task
							</span>
						</div>
					</div>
				</div>

				@if (history?.remark?.data?.action == 'updateStatus') {
					<div class="d-flex align-items-center">
						<div class="d-flex align-items-center">
							<div class="me-2 fw-bold fs-3">
								<span *ngIf="me.uuid == history?.remark?.data?.from?.uuid; else otherPeople">You</span>
								<ng-template #otherPeople>{{ history?.remark?.data?.from?.name }}</ng-template>
							</div>

							<div class="me-2 fs-4">
								mark this task status from
								<b [innerHTML]="getStatusBadge(history?.remark.data?.oldStatus, false, 'tasks')"></b>
								<span class="px-2">to</span>
								<b [innerHTML]="getStatusBadge(history?.remark.data?.newStatus, false, 'tasks')"></b>
							</div>
						</div>
					</div>
				}

				@if (['createChecklist', 'updateChecklist', 'deleteChecklist'].includes(history?.remark?.data?.action)) {
					<span class="d-flex align-items-center">
						<div class="me-2 fw-bold fs-3">
							<span *ngIf="me.uuid == history?.guardable.uuid; else otherAdmin">You</span>
							<ng-template #otherAdmin>{{ history?.guardable?.name }}</ng-template>
						</div>
						<div
							class="me-2 fs-4"
							[ngSwitch]="history?.remark?.data?.action">
							<span *ngSwitchCase="'createChecklist'">create checklist</span>
							<span *ngSwitchCase="'deleteChecklist'">delete checklist</span>
							<span *ngSwitchCase="'updateChecklist'">update checklist</span>
						</div>
						<div>
							<b>
								{{ history?.remark?.data?.itemName }}
							</b>
						</div>
					</span>
				}

				<div>
					{{ formatTime(history?.remark?.data?.time) }}
				</div>
			</div>
		</div>
	</div>
</div>
