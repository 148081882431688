import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@services/auth.service'

@Injectable({
	providedIn: 'root',
})
export class UserActivityService {
	private timeoutId: any
	private countdown: number
	private readonly INACTIVITY_THRESHOLD = 30 * 60 * 2

	constructor(
		private router: Router,
		private authService: AuthService
	) {
		this.resetTimer = this.resetTimer.bind(this)
	}

	startMonitoring() {
		this.stopMonitoring()
		this.resetTimer()
		window.addEventListener('mousemove', this.resetTimer)
		window.addEventListener('keypress', this.resetTimer)
	}

	resetTimer() {
		this.countdown = this.INACTIVITY_THRESHOLD
		if (!this.timeoutId) {
			this.timeoutId = setInterval(() => this.checkInactivity(), 1000)
		}
	}

	checkInactivity() {
		this.countdown -= 1
		if (this.countdown <= 0) {
			this.logout()
		}
	}

	stopMonitoring() {
		if (this.timeoutId) {
			clearInterval(this.timeoutId)
			this.timeoutId = null
			window.removeEventListener('mousemove', this.resetTimer)
			window.removeEventListener('keypress', this.resetTimer)
		}
	}

	logout() {
		let user = this.authService.getAuthFromLocalStorage()
		if (user) {
			this.authService.logout()
			clearInterval(this.timeoutId)
		}
	}
}
