import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import './extra-script/force-logout.js'
import './extra-script/ip.js'
import './extra-script/platform.js'
import './extra-script/update.js'

if (environment.production) {
	enableProdMode()
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err))
