import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class CacheService {
	private cache = new Map<string, { value: any; expiry: number }>()
	private ttl = 5 * 60 * 1000

	set(key: string, value: any) {
		const expiry = Date.now() + this.ttl
		this.cache.set(key, { value, expiry })
	}

	get(key: string) {
		const cachedItem = this.cache.get(key)

		if (!cachedItem) return null

		if (Date.now() > cachedItem.expiry) {
			this.cache.delete(key)
			return null
		}

		return cachedItem.value
	}

	clear() {
		this.cache.clear()
	}
}
