<ng-template #content>
	<div class="modal-header">
		<h2>History Info</h2>
		<div
			class="btn btn-sm btn-icon btn-active-color-primary"
			(click)="close()">
			<span class="bi bi-x-lg"></span>
		</div>
	</div>

	<div class="modal-body">
		<div class="row mb-8">
			<div class="col-12">
				<div class="d-flex gap-4">
					<div
						class="flex-1 d-flex align-items-center"
						[ngSwitch]="history?.event">
						<span
							*ngSwitchCase="'CREATE'"
							class="badge badge-success">
							CREATE
						</span>
						<span
							*ngSwitchCase="'READ'"
							class="badge badge-info">
							READ
						</span>
						<span
							*ngSwitchCase="'UPDATE'"
							class="badge badge-warning">
							UPDATE
						</span>
						<span
							*ngSwitchCase="'DELETE'"
							class="badge badge-danger">
							DELETE
						</span>
						<span
							*ngSwitchCase="'VIEWED'"
							class="badge badge-primary">
							VIEWED
						</span>
						<span
							*ngSwitchCase="'INACTIVE'"
							class="badge badge-secondary">
							INACTIVE
						</span>
						<span
							*ngSwitchCase="'ACTIVATED'"
							class="badge badge-secondary">
							ACTIVATED
						</span>
						<span
							*ngSwitchCase="'LOGIN'"
							class="badge badge-secondary">
							LOGIN
						</span>
						<span
							*ngSwitchCase="'LOGOUT'"
							class="badge badge-secondary">
							LOGOUT
						</span>
					</div>

					<div class="flex-2 d-flex align-items-center justify-content-end fs-3 fw-semibold text-end">
						<!-- {{ history?.itemable?.uuid ? '#' + history.itemable.uuid : '-' }} -->
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex flex-column flex-lg-row">
			<div class="flex-1 pe-0 pe-lg-6 border-right">
				<div class="row d-flex align-items-center mb-6">
					<div class="col-12">
						<div class="d-flex gap-4">
							<div class="flex-1 d-flex align-items-center">
								<div>Guard</div>
							</div>
							<div class="text-uppercase text-gray-800 fw-bold">
								{{ 'HISTORY.GUARDABLE.' + history.guardable_type | translate }}
							</div>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-center mb-6">
					<div class="col-12">
						<div class="d-flex gap-4">
							<div class="flex-1 d-flex align-items-center">
								<div>Guard Name</div>
							</div>
							<div class="text-uppercase text-gray-800 fw-bold">
								{{ history.guardable.name }}
							</div>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-center mb-6">
					<div class="col-12">
						<div class="d-flex gap-4 w-100">
							<div class="flex-1 d-flex align-items-center">
								<div>Item</div>
							</div>
							<div class="text-uppercase text-gray-800 fw-bold">
								{{ history.itemable_type || '-' }}
							</div>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-center mb-6">
					<div class="col-12">
						<div class="d-flex gap-4 w-100">
							<div class="flex-1 d-flex align-items-center">
								<div>Time</div>
							</div>
							<div class="text-uppercase text-gray-800 fw-bold">
								{{ history.created_at | customDate }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex-1 ps-0 ps-lg-6">
				<div class="row d-flex align-items-center mb-6">
					<div class="col-12">
						<div class="d-flex gap-4 flex-stack w-100">
							<div class="flex-1 d-flex align-items-center">
								<div>IP</div>
							</div>
							<div class="flex-2 text-gray-800 fw-bold text-end">
								{{ history.ip_address }}
							</div>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-center mb-6">
					<div class="col-12 d-flex">
						<div class="d-flex gap-4 flex-stack w-100">
							<div class="flex-1 d-flex align-items-center">
								<div>URL</div>
							</div>
							<div class="flex-2 text-gray-800 fw-bold text-end word-break-all">
								{{ history.url }}
							</div>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-center mb-6">
					<div class="col-12 d-flex">
						<div class="d-flex gap-4 flex-stack w-100">
							<div class="flex-1 d-flex align-items-center">
								<div>User Agent</div>
							</div>
							<div class="flex-2 text-uppercase text-gray-800 fw-bold text-end word-break-all">
								{{ history.user_agent }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			*ngIf="history.remark"
			class="mb-8">
			<div class="separator mb-8"></div>

			<div class="d-flex gap-8 flex-column flex-lg-row">
				<div class="flex-1">
					<label class="form-label">Remark</label>
					<div class="code">
						<pre>{{ history.remark | json }}</pre>
					</div>
				</div>
				<div class="flex-1"></div>
			</div>
		</div>
		@if (history?.new_value?.value || history?.old_value?.value) {
			<div>
				<div class="separator mb-8"></div>

				<div class="d-flex gap-8 flex-column flex-lg-row">
					<div class="flex-1">
						<label class="form-label">Old Values</label>
						<div class="code">
							<pre>{{ history.old_value.value | json }}</pre>
						</div>
					</div>
					<div class="flex-1">
						<label class="form-label">New Values</label>
						<div class="code">
							<pre>{{ history.new_value.value | json }}</pre>
						</div>
					</div>
				</div>
			</div>
		} @else {
			<div *ngIf="history.old_values && history.new_values">
				<div class="separator mb-8"></div>

				<div class="d-flex gap-8 flex-column flex-lg-row">
					<div class="flex-1">
						<label class="form-label">Old Values</label>
						<div class="code">
							<pre>{{ history.old_values | json }}</pre>
						</div>
					</div>
					<div class="flex-1">
						<label class="form-label">New Values</label>
						<div class="code">
							<pre>{{ history.new_values | json }}</pre>
						</div>
					</div>
				</div>
			</div>
		}
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-secondary btn-sm"
			(click)="close()">
			<span>Close</span>
		</button>
	</div>
</ng-template>
