import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'

@Injectable({
	providedIn: 'root',
})
export class WebSocketService {
	private readonly url: string = environment.websocket
	private socket$?: WebSocketSubject<any>

	public wsTokenSubject = new BehaviorSubject<string | null>(null)
	wsToken$: Observable<string | null> = this.wsTokenSubject.asObservable()

	private typeSubject = new BehaviorSubject<string>('')
	public type$ = this.typeSubject.asObservable()

	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public isLoading$ = this.isLoadingSubject.asObservable()

	constructor() {}

	setWsToken(token: string): void {
		this.wsTokenSubject.next(token)
	}

	public connect(): void {
		if (!this.socket$ || this.socket$.closed) {
			this.socket$ = webSocket(this.url)
			this.isLoadingSubject.next(false)

			this.socket$.subscribe({
				next: (message: any) => {
					if (message?.type == 'door' && message?.msg == 'success') {
						this.typeSubject.next('success')
						this.isLoadingSubject.next(false)
					}
				},
				error: () => {
					this.typeSubject.next('error')
					this.isLoadingSubject.next(false)
				},
			})
		}
	}

	public disconnect(): void {
		if (this.socket$) {
			this.socket$.complete()
			this.socket$ = undefined
		}
	}

	public sendMessage(message: any): void {
		this.isLoadingSubject.next(true)

		if (this.socket$) {
			this.socket$.next(message)
		} else {
			this.isLoadingSubject.next(false)
			console.error('WebSocket is not connected.')
		}
	}
}
