<ngb-toast
	*ngFor="let toast of toastService.toasts"
	[class]="toast.classname"
	[autohide]="toast.autohide"
	[delay]="toast.delay || 5000"
	(hide)="toastService.remove(toast)">
	<ng-template ngbToastHeader>
		<div class="me-auto d-flex align-items-center">
			<span
				class="me-2"
				*ngIf="toast.icon"
				[ngClass]="toast.icon"
				[style.color]="toast.iconColor"></span>
			<strong class="mx-1">{{ toast.headertext }}</strong>
		</div>
	</ng-template>

	<ng-template
		[ngIf]="isTemplate(toast)"
		[ngIfElse]="text">
		<ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
	</ng-template>

	<ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
