<div
	class="custom-image-input"
	[ngClass]="[width, height]">
	<!-- <div class="skeleton-wrapper" *ngIf="isImageRendering">
		<div class="skeleton-image"></div>
	</div> -->

	<div
		class="isLoading image-overlay"
		[ngClass]="blurAbleClass"
		(click)="clickImageContainer($event)">
		<div
			class="image-overlay-layer card-rounded d-flex align-items-center justify-content-center"
			[ngClass]="{ show: isImageUploading }">
			<div
				class="progressBar d-flex flex-column justify-content-center align-items-center"
				*ngIf="isImageUploading; else loaded">
				<div class="w-100">
					<ngb-progressbar
						height="20px"
						[value]="currentLoaded"
						[striped]="true"
						[animated]="true"></ngb-progressbar>
					<div class="percentageText">{{ currentLoaded }}%</div>
				</div>
				<div
					class="action mt-4"
					(click)="removeImage($event)">
					<span class="bi bi-x-lg fs-4 text-danger"></span>
				</div>
			</div>
			<ng-template #loaded>
				<div
					class="action"
					*ngIf="canViewImage"
					(click)="viewImage($event)">
					<span class="bi bi-eye fs-4 text-gray-600"></span>
				</div>
				<div
					class="action"
					(click)="triggerImageChange($event)"
					*ngIf="ableEdit">
					<span class="bi bi-pencil-square fs-4 text-primary"></span>
				</div>
				<div
					class="action"
					*ngIf="ableDelete"
					(click)="removeImage($event)">
					<span class="bi bi-trash3 fs-4 text-danger"></span>
				</div>
			</ng-template>
		</div>

		<div
			#imageContainer
			class="w-100 h-100 d-flex gap-4 align-items-center">
			<ng-container *ngIf="arraySrc.length">
				<ng-container *ngFor="let image of arraySrc; let i = index">
					<img
						#gallery
						[src]="image"
						class="image-input-wrapper"
						(load)="loadingImage()" />
				</ng-container>
			</ng-container>

			<ng-container *ngIf="!arraySrc.length">
				<img
					#gallery
					[src]="imageSrc"
					class="image-input-wrapper"
					(load)="loadingImage()" />
			</ng-container>
		</div>
	</div>

	<input
		#fileInput
		type="file"
		accept="image/*"
		class="d-none"
		(change)="changeImage($event)" />
</div>
