import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { webSocket } from 'rxjs/webSocket'

@Injectable({
	providedIn: 'root',
})
export class WebSocketService {
	private readonly url: string = environment.websocket
	public wsTokenSubject = new BehaviorSubject<string | null>(null)
	wsToken$: Observable<string | null> = this.wsTokenSubject.asObservable()

	setWsToken(token: string): void {
		this.wsTokenSubject.next(token)
	}

	constructor() {}

	public connect() {
		return webSocket(this.url)
	}
}

export class wsMessage {
	type?: string = 'vehicle_info'
	token?: string = localStorage.getItem('ws_token')!
	tracker_code: string
	status?: string
	pwd?: string

	constructor(tracker_code: string, type?: string, status?: string, pwd?: string, token?: string) {
		this.type = type || 'vehicle_info'
		this.tracker_code = tracker_code
		this.status = status
		this.pwd = pwd
		this.token = token || JSON.parse(localStorage.getItem('ws_token')!)
	}
}
