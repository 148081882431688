<ng-template #content>
	@if (isShowLoading$ | async) {
		<div
			[ngClass]="{
				'overlay overlay-block min-h-600px': isShowLoading$ | async
			}">
			<div
				class="overlay-layer card-rounded d-flex flex-column"
				*ngIf="isShowLoading$ | async">
				<div
					class="spinner-border text-black"
					role="status"></div>
			</div>
		</div>
	} @else {
		<div class="modal-header">
			<h2>
				Task
				@if (selectedTask.related_title) {
					- Related with
					<b>{{ selectedTask.related_title }}</b>
				}
			</h2>
			<div
				class="btn btn-sm btn-icon btn-active-color-primary"
				(click)="close()">
				<span class="bi bi-x-lg"></span>
			</div>
		</div>

		<app-custom-form-group
			#formGroup
			[formGroup]="form"
			(ngSubmit)="submit()">
			<div class="modal-body">
				@if (selectedTask?.uuid) {
					<div class="mb-8 d-flex flex-stack">
						<div>
							<label class="text-gray-800 fs-3 fw-semibold mb-3">Creator</label>

							<div>
								<span class="fs-4 text-gray-800">
									{{ selectedTask?.admin?.name }} - {{ selectedTask?.admin?.email }}
								</span>
							</div>
						</div>
						<div>
							{{ selectedTask.created_at | customDate }}
						</div>
					</div>
				}
				<!-- <div class="mb-8">
					<label class="text-gray-800 fs-4 fw-semibold mb-3">Involve Members</label>

					<div *ngIf="selectedMembers.length">
						<div class="symbol-group symbol-hover">
							<div
								class="symbol symbol-35px symbol-circle"
								*ngFor="let member of selectedMembers; let i = index">
								<ng-template #tipContent>
									<div class="min-w-125px">
										<b>{{ member.name }}</b>
									</div>
								</ng-template>

								<span
									class="symbol-label 40px"
									[ngClass]="avatarClass(i)"
									[ngbTooltip]="tipContent">
									{{ member.name.charAt(0) }}
								</span>
							</div>
						</div>
					</div>
					<div *ngIf="!selectedMembers.length">
						<span><i>No any members involved yet</i></span>
					</div>
				</div> -->

				<div class="custom-separator my-4"></div>

				<div class="row">
					<div class="d-flex align-items-center justify-content-between">
						<div class="fw-bold fs-3">Vehicle Info</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">License Plate:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.plate_no || '-' }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Model:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.model?.name || '-' }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Color:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.model_color?.name || '-' }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">IU:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.iu || '-' }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Chassis Number:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.chassis_no || '-' }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Engine Number:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.engine_no || '-' }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Year of Manufacture:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.year_of_manufacture || '-' }}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Service Mileage:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.service_mileage || '-' }}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Current Mileage:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">{{ selectedTask.vehicle?.last_mileage?.mileage || '-' }}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Latest Location:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div class="d-flex align-items-end align-items-md-start flex-column flex-md-row">
									@if (selectedTask.vehicle?.tracker?.latest_tracker_status_log?.latitude) {
										<u
											class="fs-6 text-primary cursor-pointer"
											(click)="goToVehicleLocation()">
											{{ 'Click me to view' }}
										</u>
										<div>
											&nbsp; at &nbsp;({{
												selectedTask.vehicle?.tracker?.latest_tracker_status_log.created_at | customDate
											}})
										</div>
									} @else {
										-
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="custom-separator my-4"></div>

				<div class="row">
					<div class="d-flex align-items-center justify-content-between">
						<div class="fw-bold fs-3">Carpark Info</div>
					</div>

					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Location:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div class="text-primary cursor-pointer">
									<u
										class="fs-6"
										(click)="goToCarpark()">
										{{ selectedTask.carpark?.name || '-' }}
									</u>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Address:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div
										class="fs-6"
										(click)="goToCarpark()">
										{{ selectedTask.carpark?.address_1 || '-' }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Postal Code:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">
										{{ selectedTask.carpark?.postal_code || '-' }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Deck:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">
										{{ selectedTask.vehicle?.deck || '-' }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row py-2">
						<div class="col-6 col-md-4">
							<div class="text-gray-800 fs-6 fw-bold">Lot:</div>
						</div>
						<div class="col-6 col-md-4 text-end text-md-start">
							<div class="fs-6">
								<div>
									<div class="fs-6">
										{{ selectedTask.vehicle?.lot || '-' }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="custom-separator my-4"></div>

				<div class="row">
					<div class="col-12 col-md-9">
						<div class="mb-6">
							<label class="text-gray-800 fs-6 fw-semibold">Title</label>
							<div class="fs-3 tect-black">{{ selectedTask.title }}</div>
						</div>

						<div class="mb-6">
							<label class="text-gray-800 fs-6 fw-semibold">Description</label>
							<div class="fs-3 tect-black">{{ selectedTask.description }}</div>
						</div>

						<div
							class="mb-6"
							[hidden]="selectedTask.uuid">
							<div class="d-flex flex-stack align-items-center">
								<label class="text-gray-800 fs-4 fw-semibold mb-3">Url</label>
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										id="urlCheck"
										[value]="true"
										formControlName="isCheckUrl" />
									<label
										class="form-check-label"
										for="urlCheck">
										Current page url
									</label>
								</div>
							</div>
							<app-custom-form-control
								[fc]="form.get('url')"
								field="url">
								<input
									type="text"
									class="form-control"
									name="url"
									formControlName="url" />
							</app-custom-form-control>
						</div>

						<!-- <div class="mb-6">
							<div class="d-flex flex-stack align-items-center mb-3">
								<label class="text-gray-800 fs-4 fw-semibold"> Checklist </label>

								<button type="button" class="btn btn-secondary btn-sm" (click)="addItem()">Add</button>
							</div>
							<div formArrayName="items">
								<div class="mb-3" *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
									<div class="d-flex align-items-center gap-4 w-100">
										<div class="form-check">
											<input class="form-check-input" type="checkbox" [value]="true" formControlName="isChecked" />
										</div>
										<div class="d-flex align-items-center gap-4 w-100">
											<span>{{ i + 1 }})</span>

											<app-custom-form-control [fc]="item.get('itemName')" field="item">
												<input type="text" class="form-control" formControlName="itemName" />
											</app-custom-form-control>
										</div>

										<i class="bi bi-x-circle fs-3 text-danger cursor-pointer" (click)="removeItem(i)"></i>
									</div>
								</div>
							</div>
						</div> -->

						@if (selectedTask?.uuid) {
							<app-task-comment [taskId]="selectedTask.uuid"></app-task-comment>
						}

						<app-task-history
							[histories]="selectedTask?.histories"
							*ngIf="selectedTask?.histories?.length"></app-task-history>
					</div>

					<div class="col-12 col-md-3">
						<div class="mb-6">
							<label class="text-gray-800 fs-4 fw-semibold mb-3">Vehicle Action</label>
							<div class="d-flex flex-column gap-3">
								<button
									type="button"
									(click)="operateCar('door', '1')"
									class="btn btn-info btn-sm w-100 text-start d-flex align-items-center"
									[disabled]="isOperationLoading$ | async">
									<ng-container *ngIf="isOperationLoading$ | async">
										<span
											class="indicator-progress"
											[style.display]="'block'">
											Please wait...
											<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									</ng-container>
									<ng-container *ngIf="!(isOperationLoading$ | async)">
										<div class="d-flex align-items-center gap-3">
											<i class="bi bi-unlock-fill fs-3 text-white"></i>
											<span>Unlock</span>
										</div>
									</ng-container>
								</button>

								<button
									type="button"
									(click)="operateCar('door', '0')"
									class="btn btn-danger btn-sm w-100 text-start d-flex align-items-center"
									[disabled]="isOperationLoading$ | async">
									<ng-container *ngIf="isOperationLoading$ | async">
										<span
											class="indicator-progress"
											[style.display]="'block'">
											Please wait...
											<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									</ng-container>
									<ng-container *ngIf="!(isOperationLoading$ | async)">
										<div class="d-flex align-items-center gap-3">
											<i class="bi bi-lock-fill fs-3 text-white"></i>
											<span>Lock</span>
										</div>
									</ng-container>
								</button>

								<button
									type="button"
									class="btn btn-sm btn-warning w-100 text-start d-flex align-items-center"
									(click)="goToBlockTime()">
									<div class="d-flex align-items-center gap-3">
										<i class="bi bi-slash-circle fs-3 text-white"></i>
										<span class="text-white">Block Time</span>
									</div>
								</button>

								<!-- @if (selectedTask.status !== 2) {
									<button
										type="button"
										(click)="markAsComplete()"
										class="btn btn-primary btn-sm w-100 text-start d-flex align-items-center"
										[disabled]="isLoading$ | async">
										<ng-container *ngIf="isLoading$ | async">
											<span
												class="indicator-progress"
												[style.display]="'block'">
												Please wait...
												<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
										</ng-container>
										<ng-container *ngIf="!(isLoading$ | async)">Enable vehicle</ng-container>
									</button>
								} -->

								<!-- <app-custom-form-control class="flex-2" [fc]="form.get('status')" field="status">
									<ng-select
										formControlName="status"
										class="custom w-100 text-start d-flex align-items-center"
										[clearable]="false"
										[clearOnBackspace]="false">
										@for (status of status; track $index) {
											<ng-option class="form-control" [value]="status.value"> {{ status.name }} </ng-option>
										}
									</ng-select>
								</app-custom-form-control> -->

								<!-- @if (selectedTask?.admin?.uuid == me.uuid || !selectedTask?.uuid) {
								<div class="d-flex flex-stack align-items-center mt-3">
									<label class="form-check-label flex-3" for="taskEnabled">
										<span *ngIf="form.get('is_able_edit').value">Allow assignee edit</span>
										<span *ngIf="!form.get('is_able_edit').value">Disallow assignee edit</span>
									</label>
									<app-custom-form-control class="flex-2" [fc]="form.get('is_able_edit')" field="able edit">
										<div class="form-check form-switch form-check-custom form-check-solid justify-content-end">
											<input
												name="is_able_edit"
												formControlName="is_able_edit"
												class="form-check-input ms-4"
												type="checkbox"
												id="taskEnabled" />
										</div>
									</app-custom-form-control>
								</div>
							} -->
							</div>
						</div>
						<div class="col-12">
							<label class="text-gray-800 fs-4 fw-semibold mb-3">Operation Action</label>
							<div class="d-flex flex-column gap-3">
								<button
									type="button"
									(click)="upload()"
									class="btn btn-info btn-sm w-100 text-start d-flex align-items-center"
									[disabled]="isUploadLoading$ | async">
									<ng-container *ngIf="isUploadLoading$ | async">
										<span
											class="indicator-progress"
											[style.display]="'block'">
											Please wait...
											<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									</ng-container>
									<ng-container *ngIf="!(isUploadLoading$ | async)">
										<div class="d-flex align-items-center gap-3">
											<i class="bi bi-cloud-upload text-white"></i>
											<span>Upload Document</span>
										</div>
									</ng-container>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer d-flex flex-stack">
				<div></div>
				<div class="d-flex gap-4">
					<button
						type="button"
						class="btn btn-secondary btn-sm"
						(click)="close()">
						<span>Close</span>
					</button>

					<!-- <button
						*ngIf="selectedTask?.admin?.uuid == me.uuid && selectedTask.oriStatus != 2"
						(click)="warning()"
						type="button"
						class="btn btn-danger btn-sm"
						[disabled]="form.invalid || (isLoading$ | async)">
						<ng-container *ngIf="isLoading$ | async">
							<span
								class="indicator-progress"
								[style.display]="'block'">
								Please wait...
								<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</ng-container>
						<ng-container *ngIf="!(isLoading$ | async)">Delete</ng-container>
					</button> -->
					<!-- <button
						type="submit"
						class="btn btn-primary btn-sm"
						[disabled]="form.invalid || (isLoading$ | async)">
						<ng-container *ngIf="isLoading$ | async">
							<span
								class="indicator-progress"
								[style.display]="'block'">
								Please wait...
								<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</ng-container>
						<ng-container *ngIf="!(isLoading$ | async)">Submit</ng-container>
					</button> -->
				</div>
			</div>
		</app-custom-form-group>
	}
</ng-template>
