import { CommonModule, DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatLineModule, MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { ClickOutsideDirective } from '@directives/clickOutside.directive'
import { ScrollDirective } from '@directives/scroll.directive'
import { OpenTaskModalDirective } from '@directives/task.directive'
import {
	NgbAccordionCollapse,
	NgbCollapseModule,
	NgbDropdownModule,
	NgbModule,
	NgbPagination,
	NgbProgressbarModule,
	NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap'
import { NgSelectModule } from '@ng-select/ng-select'
import { TranslateModule } from '@ngx-translate/core'
import { ConvertPricingPipe } from '@pipes/convertPricing.pipe'
import { CustomDatePipe } from '@pipes/date.pipe'
import { FlatpickrModule } from 'angularx-flatpickr'
import { DndModule } from 'ngx-drag-drop'
import { NGX_ECHARTS_CONFIG, NgxEchartsModule } from 'ngx-echarts'
import { ClipboardComponent } from './clipboard/clipboard.component'
import { CustomError403 } from './custom-error/error403/error403.component'
import { CustomTableComponent } from './custom-table/custom-table.component'
import { AdvancedSearchComponent } from './form/advanced-search/advanced-search.component'
import { CustomCountryButtonComponent } from './form/country/country.component'
import { CustomFormControl } from './form/form-control/form-control.component'
import { CustomFormGroup } from './form/form-group/form-group.component'
import { ImageInputComponent } from './form/image/image.component'
import { HistoryComponent } from './history/history.component'
import { HistoryDetailModalComponent } from './history/modal/detail.component'
import { KeeniconComponent } from './keenicon/keenicon.component'
import { ToolbarComponent } from './layout/components/toolbar/toolbar.component'
import { ReportSelectComponent } from './report/select/select.component'
import { TaskCommentComponent } from './task/comment.component'
import { TaskHistoryComponent } from './task/history.component'
import { TaskIndexComponent } from './task/index.component'
import { TaskListModalComponent } from './task/list.component'

@NgModule({
	declarations: [
		TaskHistoryComponent,
		ToolbarComponent,
		KeeniconComponent,
		HistoryComponent,
		HistoryDetailModalComponent,
		ImageInputComponent,
		AdvancedSearchComponent,
		CustomFormGroup,
		CustomFormControl,
		CustomTableComponent,
		ReportSelectComponent,
		ClickOutsideDirective,
		OpenTaskModalDirective,
		ScrollDirective,
		CustomDatePipe,
		ConvertPricingPipe,
		CustomError403,
		ClipboardComponent,
		TaskIndexComponent,
		TaskListModalComponent,
		TaskCommentComponent,
		CustomCountryButtonComponent,
	],
	imports: [
		NgbDropdownModule,
		ReactiveFormsModule,
		FormsModule,
		FlatpickrModule.forRoot(),
		CommonModule,
		TranslateModule,
		NgbPagination,
		NgbProgressbarModule,
		NgbToastModule,
		NgSelectModule,
		NgbModule,
		NgbCollapseModule,
		NgbAccordionCollapse,
		NgxEchartsModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatButtonModule,
		MatDialogModule,
		DndModule,
		MatIconModule,
		MatListModule,
		MatCardModule,
		MatLineModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatTabsModule,
		MatToolbarModule,
	],
	exports: [
		ToolbarComponent,
		KeeniconComponent,
		HistoryComponent,
		HistoryDetailModalComponent,
		CustomTableComponent,
		ImageInputComponent,
		AdvancedSearchComponent,
		CustomFormGroup,
		CustomFormControl,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		TranslateModule,
		NgSelectModule,
		NgbModule,
		NgbDropdownModule,
		NgbCollapseModule,
		NgbAccordionCollapse,
		FlatpickrModule,
		NgxEchartsModule,
		ReportSelectComponent,
		ClickOutsideDirective,
		OpenTaskModalDirective,
		ScrollDirective,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatButtonModule,
		MatDialogModule,
		CustomError403,
		CustomDatePipe,
		ConvertPricingPipe,
		ClipboardComponent,
		TaskIndexComponent,
		TaskListModalComponent,
		TaskCommentComponent,
		TaskHistoryComponent,
		CustomCountryButtonComponent,
		DndModule,
		MatIconModule,
		MatListModule,
		MatCardModule,
		MatLineModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatTabsModule,
		MatToolbarModule,
	],
	providers: [
		DatePipe,
		CustomDatePipe,
		ConvertPricingPipe,
		{
			provide: NGX_ECHARTS_CONFIG,
			useFactory: () => ({ echarts: () => import('echarts') }),
		},
	],
})
export class SharedModule {}
