// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

export interface Locale {
	lang: string
	data: any
}

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language'

@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	// Private properties
	private langIds: any = []

	constructor(private translate: TranslateService) {
		// add new langIds to the list
		this.translate.addLangs(['en'])

		// this language will be used as a fallback when a translation isn't found in the current language
		this.translate.setDefaultLang('en')
	}

	loadTranslations(data: any): void {
		for (let lang in data) {
			this.translate.setTranslation(lang, data[lang], true)
			this.langIds.push(lang)
		}

		this.translate.addLangs(this.langIds)
		this.translate.use(this.getSelectedLanguage())
	}

	setLanguage(lang: string) {
		if (lang) {
			this.translate.use(this.translate.getDefaultLang())
			this.translate.use(lang)
			localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, lang)
		}
	}

	/**
	 * Returns selected language
	 */
	getSelectedLanguage(): any {
		return localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) || this.translate.getDefaultLang()
	}
}
