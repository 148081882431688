<span
	*ngIf="type === 'duotone'"
	class="ki-{{ type }} ki-{{ name }}{{ class ? ' ' + class : '' }}">
	<span
		*ngFor="let i of [].constructor(pathsNumber); let idx = index"
		class="path{{ idx + 1 }}"></span>
</span>
<span
	*ngIf="type !== 'duotone'"
	class="ki-{{ type }} ki-{{ name }}{{ class ? ' ' + class : '' }}"></span>
