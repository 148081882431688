<div class="mb-6">
	<label class="text-gray-800 fs-4 fw-semibold mb-3">Comments</label>
	<app-custom-form-group
		class="d-flex flex-column"
		#formGroup
		[formGroup]="form"
		(ngSubmit)="submit()">
		<app-custom-form-control
			[fc]="form.get('comment')"
			field="comment">
			<textarea
				class="form-control"
				placeholder="Write a comment"
				name="comment"
				formControlName="comment"
				rows="3"></textarea>
		</app-custom-form-control>
		<button
			type="submit"
			class="btn btn-primary btn-sm align-self-end mt-4"
			[disabled]="form.invalid || (isLoading$ | async)">
			<ng-container *ngIf="isLoading$ | async">
				<span
					class="indicator-progress"
					[style.display]="'block'">
					Please wait...
					<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
				</span>
			</ng-container>
			<ng-container *ngIf="!(isLoading$ | async)">Comment</ng-container>
		</button>
	</app-custom-form-group>
</div>

<div class="mb-6">
	<div
		class="max-h-450px overflow-scroll pe-4"
		(scroll)="onScroll($event)">
		<div
			*ngFor="let comment of comments; let i = index"
			class="d-flex align-items-center mb-4 gap-4">
			<div class="symbol-group symbol-hover">
				<div class="symbol symbol-50px symbol-circle">
					<ng-template #tipContent>
						<div class="min-w-125px">
							<b>{{ comment.admin.name }}</b>
						</div>
					</ng-template>

					<span
						class="symbol-label 40px"
						[ngbTooltip]="tipContent">
						{{ comment.admin?.name.charAt(0) }}
					</span>
				</div>
			</div>
			<div class="d-flex flex-column gap-1">
				<div class="fs-3 text-gray-800">{{ comment.comment }}</div>
				<small>{{ comment.created_at | customDate }}</small>
			</div>
		</div>
		@if (comments.length == 0) {
			<div class="d-flex justify-content-center"><i>-- No more comments --</i></div>
		}
	</div>
	@if (isListCommentLoading$ | async) {
		<div
			[ngClass]="{
				'overlay overlay-block min-h-100px': isListCommentLoading$ | async
			}">
			<div
				class="overlay-layer card-rounded d-flex flex-column"
				*ngIf="isListCommentLoading$ | async">
				<div
					class="spinner-border text-black"
					role="status"></div>
			</div>
		</div>
	}
</div>
