import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { isEmpty } from '@helper/helper'
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import { CustomFormControlService } from '@services/custom-form-control.service'
@Component({
	selector: 'app-country-button',
	templateUrl: './country.component.html',
	styleUrls: ['./country.component.scss'],
})
export class CustomCountryButtonComponent implements OnInit {
	constructor(
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef,
		private customFormControlService: CustomFormControlService
	) {}

	@ViewChild('content') childContent: ElementRef
	@ViewChild('myDrop') myDrop: NgbDropdown

	@Input() fc: FormControl
	@Output() selectCountry: EventEmitter<any> = new EventEmitter<any>()

	countries: any = [
		{ text: 'Singapore', value: 'sg', src: '/assets/partner/singapore.png', disabled: false },
		{ text: 'Indonesia', value: 'id', src: '/assets/partner/indonesia.png', disabled: true },
		{ text: 'Japan', value: 'jp', src: '/assets/partner/japan.png', disabled: true },
	]

	ngOnInit() {
		if (isEmpty(localStorage.getItem('country'))) {
			localStorage.setItem('country', 'sg')
		}
	}

	selectedCountry: string = localStorage.getItem('country') || 'sg'

	select(countryCode: any) {
		let country = this.countries.find((country: any) => country.value == countryCode)

		if (country.disabled == true) {
			return
		}

		this.myDrop.close()
		this.selectedCountry = countryCode
		this.selectCountry.emit(countryCode)
		localStorage.setItem('country', this.selectedCountry)
	}

	get country() {
		let country = this.countries.find((country: any) => country.value == this.selectedCountry)
		return country
	}
}
