import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-history-detail-modal',
	templateUrl: './detail.component.html',
	styleUrls: ['./detail.component.scss'],
})
export class HistoryDetailModalComponent implements OnInit, AfterViewInit {
	@Input() public history: any

	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>()

	@ViewChild('content') content: any

	private modalRef: NgbModalRef

	constructor(
		private modalService: NgbModal,
		private router: Router
	) {}

	async ngOnInit() {}

	async ngAfterViewInit() {
		await this.open()
	}

	open(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.modalRef = this.modalService.open(this.content, {
				size: 'xl',
				keyboard: true,
			})
			this.modalRef.result.then(resolve, (reason: any) => {
				this.close({})
			})
		})
	}
	async close(data: any = {}): Promise<void> {
		this.modalRef.close()
		this.closeModal.emit(data)
	}
}
