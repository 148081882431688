import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import { UserModel } from '../../components/auth/models/user.model'

const API_USERS_URL = `${environment.apiUrl}/auth`

@Injectable({
	providedIn: 'root',
})
export class AuthHTTPService {
	constructor(private http: HttpClient) {}

	// public methods
	login(email: string, password: string, country: any = 'sg'): Observable<any> {
		const headers = new HttpHeaders({
			Country: country,
		})
		return this.http.post(
			`/login`,
			{
				email,
				password,
			},
			{
				headers: headers, // Pass the headers object
			}
		)
	}

	logout(): Observable<UserModel> {
		return this.http.post<UserModel>('/logout', {})
	}
	// CREATE =>  POST: add a new user to the server
	createUser(user: UserModel): Observable<UserModel> {
		return this.http.post<UserModel>(API_USERS_URL, user)
	}

	// Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
	forgotPassword(email: string): Observable<boolean> {
		return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
			email,
		})
	}
}
