import { HttpHeaders } from '@angular/common/http'
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { VehicleApi } from '@api/admin/vehicle.api'
import { isEmpty } from '@helper/helper'
import { url } from '@helper/validators'
import { wsMessage } from '@helper/websocket'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { AuthService } from '@services/auth.service'
import { TaskService } from '@services/task.service'
import { WebSocketService } from '@services/webSocket.service'
import { ToastService } from '@shared/toast/toast.service'
import { cloneDeep } from 'lodash'
import { Subject } from 'rxjs'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'
import Swal from 'sweetalert2'
@Component({
	selector: 'app-task-modal',
	templateUrl: './create.component.html',
})
export class TaskModalComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>()

	@ViewChild('content') content: any

	private modalRef: NgbModalRef

	isUploadLoading$ = this.vehicleApi.isUploadLoading$
	isShowLoading$ = this.taskService.isShowLoading$
	isLoading$ = this.taskService.isLoading$
	form: FormGroup
	data: any = {}
	selectedTask: any = {}
	selectedMembers: any = []
	isJoined: boolean = false
	fullUrl: string
	action: any
	webSocketSubject: any
	isOperationLoading$ = this.webSocketService.isLoading$
	type: any
	code: string

	me = this.authService.getAuthFromLocalStorage()
	private unsubscribe$ = new Subject<void>()
	constructor(
		private ngbModalService: NgbModal,
		private fb: FormBuilder,
		private authService: AuthService,
		private taskService: TaskService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private webSocketService: WebSocketService,
		private toastService: ToastService,
		private vehicleApi: VehicleApi
	) {
		this.fullUrl = window.location.origin + this.router.url

		this.taskService.taskId$.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$)).subscribe((data: any) => {
			this.data = data
			if (data.taskId) {
				this.getTask(data.taskId)
			}
		})

		// this.webSocketSubject.subscribe((message: any) => {
		// 	console.log(message)
		// 	if (message.type === 'vehicle_info') {
		// 	}

		// 	if (message.type === 'cipher') {
		// 	}

		// 	if (message.msg) {
		// 		var icon = 'bi bi-check-circle-fill'
		// 		var iconColor = '#01a23b'
		// 		if (!message.status) {
		// 			icon = 'bi bi-exclamation-triangle-fill'
		// 			iconColor = '#f90'
		// 		}

		// 		this.toastService.show(message.msg, {
		// 			icon: icon,
		// 			iconColor: iconColor,
		// 			delay: 3000,
		// 			autohide: true,
		// 			headertext: 'Tracker Operation',
		// 		})
		// 		this.cdr.detectChanges()
		// 	}
		// })

		// this.taskService.taskId$.subscribe((taskId: any) => {

		// 	if (!isEmpty(taskId)) {
		// 		if (this.selectedTask.uuid != taskId) {
		// 			this.getTask(taskId)
		// 		}
		// 	} else {
		// 		this.selectedTask = {}
		// 	}
		// })
	}

	status: any = [
		{ name: 'New', value: 0 },
		{ name: 'Replied', value: 3 },
		{ name: 'In Progress', value: 1 },
		{ name: 'Complete', value: 2 },
	]

	ngOnInit() {
		this.initForm()
	}
	ngOnDestroy() {
		this.unsubscribe$.next()
		this.unsubscribe$.complete()
	}

	async ngAfterViewInit() {
		await this.open()
	}

	getTask(taskId: string) {
		this.taskService.show(taskId).subscribe({
			next: (res: any) => {
				this.selectedTask = res.data.task
				this.code = this.selectedTask?.vehicle?.tracker?.code || ''
				this.webSocketService.setWsToken(this.code)
				this.webSocketService.connect()

				// const message = new wsMessage(this.code, 'vehicle_info')

				// this.webSocketService.sendMessage(message)

				let icon = 'bi bi-check-circle-fill'
				let iconColor = '#01a23b'
				this.webSocketService.type$.subscribe((type) => {
					if (isEmpty(type) || isEmpty(this.action)) {
						return
					}

					console.log(type)

					if (type == 'success') {
						this.type = type
						this.toastService.show(`${this.action} successfully`, {
							icon: icon,
							iconColor: iconColor,
							delay: 3000,
							autohide: true,
							headertext: 'Tracker Operation',
						})
					} else {
						this.toastService.show(`This tracker got issue, unable to operate`, {
							icon: icon,
							iconColor: iconColor,
							delay: 3000,
							autohide: true,
							headertext: 'Tracker Operation',
						})
					}
				})

				this.initForm()
			},
		})
	}

	avatarClass(index: number): string {
		index = index % 5
		let colors: any = {
			0: 'bg-light-primary text-primary',
			1: 'bg-light-warning text-warning',
			2: 'bg-light-danger text-danger',
			3: 'bg-light-info text-info',
			4: 'bg-light-success text-success',
		}

		return colors[index]
	}

	open(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.modalRef = this.ngbModalService.open(this.content, {
				size: 'xl',
				backdrop: 'static',
				keyboard: false,
			})
			this.modalRef.result.then(resolve, (reason: any) => {
				this.close({})
			})
		})
	}

	initForm() {
		if (!this.form) {
			this.form = this.fb.group({
				title: [this?.selectedTask?.title || '', []],
				status: [this?.selectedTask?.oriStatus || 0, []],
				description: [this?.selectedTask?.description || '', []],
				is_able_edit: [true, []],
				// url: [this?.selectedTask?.url || '', [, url()]],
				url: [this?.selectedTask?.url || '', [url()]],
				isCheckUrl: [this?.selectedTask?.url ? true : false, []],
				items: this.fb.array([]),
			})

			if (this.selectedTask?.url) {
				this.form.get('url')?.patchValue(this.selectedTask.url)
			} else {
				this.form.get('url')?.patchValue(this.fullUrl)
			}

			this.form.get('url')?.disable()
			this.form.get('isCheckUrl')?.valueChanges.subscribe((value) => {
				if (value == true) {
					this.form.get('url')?.patchValue(this.fullUrl)
					this.form.get('url')?.disable()
				} else {
					if (this.selectedTask?.url) {
						this.form.get('url')?.patchValue(this.selectedTask.url)
					} else {
						this.form.get('url')?.patchValue('')
					}
					this.form.get('url')?.enable()
				}
			})
		}

		if (!isEmpty(this.selectedTask)) {
			this.form.patchValue({
				title: this.selectedTask.title,
				description: this.selectedTask.description,
				is_able_edit: this.selectedTask.is_able_edit ? true : false,
			})

			// if (this.selectedTask?.checklists?.length) {
			// 	this.selectedTask.checklists.forEach((item: any) => {
			// 		const itemFormGroup = this.fb.group({
			// 			itemName: [item.title, Validators.required],
			// 			id: [item.id, []],
			// 			isChecked: [item.completed_at ? true : false, []],
			// 		})

			// 		this.items.push(itemFormGroup)
			// 	})
			// }

			if (this.selectedTask?.assignments?.length) {
				this.selectedTask.assignments
					.map((assignment: any) => assignment.assign_to)
					.forEach((member: any) => {
						this.selectMember(member)
					})
			}

			if (this.selectedTask?.is_able_edit) {
				this.form.enable()
			} else {
				this.form.disable()
				this.form.get('items')?.enable()
			}

			if (this.selectedTask.admin.uuid == this.me.uuid) {
				this.form.enable()
			}

			this.form.get('title')?.disable()
			this.form.get('description')?.disable()
		}
	}

	addItem() {
		const itemFormGroup = this.fb.group({
			itemName: ['', Validators.required],
			isChecked: [false, []],
		})

		this.items.push(itemFormGroup)
	}

	removeItem(index: number) {
		this.items.removeAt(index)
	}

	get items() {
		return this.form.get('items') as FormArray
	}

	async close(data: any = {}): Promise<void> {
		this.modalRef.close()
		this.taskService.close()
		this.webSocketService.disconnect()
		this.closeModal.emit(data)
	}

	join() {
		this.isJoined = true
		this.selectedMembers.push(this.me)
	}

	leave(member: any) {
		let index = this.selectedMembers.findIndex((admin: any) => admin.uuid == member.uuid)

		if (index > -1) {
			if (member.uuid == this.me.uuid) {
				this.isJoined = false
			}
			this.selectedMembers.splice(index, 1)
		}
	}

	selectMember(member: any) {
		let selected = this.selectedMembers.find((admin: any) => admin.uuid == member.uuid)

		if (!selected) {
			if (member.uuid == this.me.uuid) {
				this.join()
			} else {
				this.selectedMembers.push(member)
			}
		}
	}

	unSelectMember(member: any) {
		this.leave(member)
	}

	submit() {
		if (this.form.invalid) {
			return
		}

		if (this.selectedTask?.uuid) {
			this.updateTask()
		} else {
			this.createTask()
		}
	}

	createTask() {
		let value = cloneDeep(this.form.getRawValue())

		value = {
			...this.data,
			...value,
			selectedMembers: this.selectedMembers.map((member: any) => member.uuid),
		}

		this.taskService.create(value).subscribe({
			next: (res) => {
				Swal.fire({
					title: 'System Reminder',
					text: 'Task create successfully.',
					icon: 'success',
				})
				this.taskService.submit()
				this.close({})
			},
			error: () => {},
		})
	}

	updateTask(params: any = {}) {
		let value = cloneDeep(this.form.getRawValue())

		value = {
			...value,
			...params,
			selectedMembers: this.selectedMembers.map((member: any) => member.uuid),
		}

		this.taskService.update(this.selectedTask.uuid, value).subscribe({
			next: (res) => {
				Swal.fire({
					title: 'System Reminder',
					text: 'Task update successfully.',
					icon: 'success',
				})
				this.taskService.submit()
				this.close({})
			},
			error: () => {},
		})
	}

	warning(task: any) {
		Swal.fire({
			title: 'System Reminder',
			text: 'Do you want to delete this task?',
			icon: 'warning',
			showCancelButton: true,
			allowOutsideClick: false,
			confirmButtonText: 'Delete',
			customClass: {
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-secondary ml-1',
			},
			buttonsStyling: false,
		}).then((res) => {
			if (res.isConfirmed) {
				this.deleteTask()
			}
		})
	}

	deleteTask() {
		if (!this.selectedTask.uuid) {
			return
		}

		this.taskService.delete(this.selectedTask.uuid).subscribe({
			next: () => {
				Swal.fire({
					title: 'System Reminder',
					text: 'Task has been deleted successfully',
					icon: 'success',
				})
				this.taskService.submit()
				this.close({})
			},
			error: () => {},
		})
	}

	warningComplete() {
		Swal.fire({
			title: 'System Reminder',
			text: 'Do you want mark this task as completed?',
			icon: 'warning',
			showCancelButton: true,
			allowOutsideClick: false,
			confirmButtonText: 'Mark as completed',
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-secondary ml-1',
			},
			buttonsStyling: false,
		}).then((res) => {
			if (res.isConfirmed) {
				this.updateTask({ markAsComplete: true })
				// this.markAsComplete()
			}
		})
	}

	warningProcessing() {
		Swal.fire({
			title: 'System Reminder',
			text: 'Are you ready to process this task?',
			icon: 'info',
			showCancelButton: true,
			allowOutsideClick: false,
			confirmButtonText: 'Mark as processing',
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-secondary ml-1',
			},
			buttonsStyling: false,
		}).then((res) => {
			if (res.isConfirmed) {
				this.updateTask({ markAsProcessing: true })
			}
		})
	}

	markAsComplete() {
		if (!this.selectedTask.uuid) {
			return
		}
		this.taskService.markAsComplete(this.selectedTask.uuid).subscribe({
			next: () => {
				Swal.fire({
					title: 'System Reminder',
					text: 'Task has been mark as complete',
					icon: 'success',
				})
				this.taskService.submit()
				this.close({})
			},
			error: () => {},
		})
	}

	goToCarpark = () => {
		let lat = this.selectedTask.carpark.latitude
		let lng = this.selectedTask.carpark.longitude
		const url = `https://www.google.com/maps?q=${lat},${lng}&travelmode=d`
		window.open(url, '_blank')
	}

	goToBlockTime = () => {
		this.close({})
		this.router.navigate(['/vehicle-management/vehicle', this.selectedTask.vehicle.uuid, 'block-time'])
	}

	goToVehicleLocation = () => {
		let lat = this.selectedTask.vehicle?.tracker?.latest_tracker_status_log?.latitude || ''
		let lng = this.selectedTask.vehicle?.tracker?.latest_tracker_status_log?.longitude || ''
		const url = `https://www.google.com/maps?q=${lat},${lng}&travelmode=d`
		window.open(url, '_blank')
	}

	operateCar(type: string, status?: string) {
		if (status == '0') {
			this.action = 'Lock'
		} else {
			this.action = 'Unlock'
		}
		try {
			const message = new wsMessage(this.code, type, status)

			this.webSocketService.sendMessage(message)
		} catch (err) {
		} finally {
			setTimeout(() => {
				this.cdr.detectChanges()
			}, 2000)
		}
	}

	upload() {
		const fileInput = document.createElement('input')
		fileInput.type = 'file'
		fileInput.accept = '.pdf'
		fileInput.click()

		fileInput.onchange = (event: any) => {
			const file = event.target.files[0]
			if (file) {
				this.uploadPdf(file)
			}
		}
	}

	uploadPdf(file: File): void {
		const reader = new FileReader()
		reader.onload = () => {
			const binaryString = reader.result as string

			const headers = new HttpHeaders({
				'Content-Type': 'application/octet-stream',
				'File-Name': file.name,
			})

			this.vehicleApi.uploadAttachment(this.selectedTask.vehicle_id, binaryString, headers).subscribe({
				next: (res: any) => {
					Swal.fire({
						title: 'System Reminder',
						text: 'Agreement has been successfully uploaded',
						icon: 'success',
					})
				},
			})
		}

		reader.readAsDataURL(file)
	}
}
