import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { CustomFormControlService } from '@services/custom-form-control.service'
@Component({
	selector: 'app-custom-form-control',
	templateUrl: './form-control.component.html',
	styleUrls: ['./form-control.component.scss'],
})
export class CustomFormControl implements OnInit, AfterViewInit {
	constructor(
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef,
		private customFormControlService: CustomFormControlService
	) {}

	@ViewChild('content') childContent: ElementRef

	@Input() fc: FormControl
	@Input() class: string | null
	@Input() field: string

	ngOnInit() {
		this.customFormControlService.registerFormControl(this.fc, this)
	}

	ngAfterViewInit() {
		if (this.childContent) {
			this.fc.valueChanges.subscribe((value: any) => {
				this.checkFormIsValid()
			})

			this.fc.statusChanges.subscribe((status) => {
				this.fc.markAllAsTouched()
				this.fc.markAsDirty()
				this.checkFormIsValid()
			})
		}

		setTimeout(() => {
			// this.fc.setErrors(null)
			// this.cdr.detectChanges()
		}, 0)

		this.childContent.nativeElement.addEventListener('click', this.handleClick.bind(this))
	}

	handleClick() {
		this.fc.markAsDirty()
		this.fc.markAllAsTouched()
		this.checkFormIsValid()
		this.cdr.detectChanges()
	}

	addClass(element: HTMLElement, className: string) {
		this.renderer.addClass(element, className)
		const children = element.querySelectorAll('*')
		children.forEach((child) => {
			this.renderer.addClass(child, className)
		})
	}

	removeClass(element: HTMLElement, className: string) {
		this.renderer.removeClass(element, className)
		const children = element.querySelectorAll('*')
		children.forEach((child) => {
			this.renderer.removeClass(child, className)
		})
	}

	public checkFormIsValid() {
		this.removeClass(this.childContent.nativeElement, 'is-invalid')
		if (this.fc.dirty && this.fc.invalid) {
			this.addClass(this.childContent.nativeElement, 'is-invalid')
		}
	}
}
