import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { TaskService } from '@services/task.service'
@Component({
	selector: 'app-task-comment',
	templateUrl: './comment.component.html',
})
export class TaskCommentComponent implements OnInit {
	constructor(
		private fb: FormBuilder,
		private taskService: TaskService,
		private cdr: ChangeDetectorRef
	) {}
	@Input() taskId: any
	comments: any = []
	form: FormGroup
	page: number = 1
	isEmptyData: boolean = false
	isLoading$ = this.taskService.isCommentLoading$
	isListCommentLoading$ = this.taskService.isListCommentLoading$

	ngOnInit(): void {
		this.initForm()
		this.getComment()
	}

	submit() {
		this.taskService.comment(this.taskId, this.form.value).subscribe({
			next: (res: any) => {
				let comment = res.data.comment
				this.form.get('comment')?.patchValue('')
				this.comments.unshift(comment)
			},
		})
	}

	initForm() {
		this.form = this.fb.group({
			comment: ['', []],
		})
	}

	getComment() {
		let params = {
			page: this.page,
		}
		this.taskService.listComment(this.taskId, { params: params }).subscribe({
			next: (res: any) => {
				if (!res.data.comments.data.length) {
					this.isEmptyData = true
					this.cdr.detectChanges()
					return
				}

				this.page += 1
				this.comments = [...this.comments, ...res.data.comments.data]
			},
		})
	}

	onScroll(event: any): void {
		if (this.isEmptyData) {
			this.cdr.detectChanges()
			return
		}

		const scrollContainer = event.target

		if (scrollContainer.scrollTop + scrollContainer.clientHeight + 1 >= scrollContainer.scrollHeight) {
			this.getComment()
		}
	}
}
